import {GuidType} from '../../../values/generic-type-defintions'
import LoadingState from '../../../values/loading-state-enum'

export interface AuditLogReduxState {
    loading: LoadingState | undefined
    data: AuditEntry[] | undefined
}

export const DEFAULT_AUDIT_LOG_STATE: AuditLogReduxState = {
    loading: LoadingState.NotPopulated,
    data: [],
}

export interface AuditEntry {
    identity: GuidType
    type: AuditLogAuditType
    message: string
    userId: GuidType
    source: AuditLogSourceType
    createdAt: string
    visibility: AuditVisibilityType
}

export enum AuditLogSourceType {
    UI = 'UI',
    API = 'API',
}

export enum AuditVisibilityType {
    ALL = 'ALL',
    NONE = 'NONE',
}

export enum AuditLogAuditType {
    PAGE_ACCESS = 'Page Access',
    DETAIL_PANEL_ACCESS = 'Detail Panel Access',
    VESSEL_SETTINGS_ACCESS = 'Vessel Settings Access',
    INTERLINK_CLICK = 'Interlink clicked',
    BUTTON_CLICK = 'Button clicked',
    SELECT_SAVED_FILTER = 'Select saved filter',
    SAVE_NEW_FILTER = 'Save new filter',
    DELETE_SAVED_FILTER = 'Delete saved filter',
    DOWNLOAD_FILE = 'Download File',
    SORT_BY_COLUMN = 'Sort by column',
    SET_ANALYSIS_PERIOD = 'Set analysis period',
    SET_ANALYSIS_TYPE = 'Set analysis type',
    SET_FILTER_BY_TYPE = 'Set filter by type',
    SET_COLUMN_TO_BE_DISPLAYED = 'Set column',
    SET_CHANGE_ASSET_VALUES = 'Set change asset values',
}

export enum AuditLogPageType {
    DASHBOARD = 'dashboard',
    INCIDENTS = 'incidents',
    REPORTS = 'reports',
    NETWORK_INVENTORY = 'network inventory',
    USB_INVENTORY = 'usb inventory',
    SOFTWARE_INVENTORY = 'software inventory',
    NMEA_INVENTORY = 'nmea inventory',
    USERS = 'users',
    MY_VESSELS = 'my-vessels',
    METRICS = 'metrics beta',
    POLICY_MANAGEMENT = 'policy management',
    VESSEL_MANAGEMENT = 'vessel management',
    VESSELS_BETA = 'vessels beta',
    INCIDENTS_REPORT = 'incidents report',
}

export enum DownloadFileType {
    EXCEL = 'Excel',
    CSV = 'Csv',
    PDF = 'Pdf',
    MISC = 'Misc',
}
